import Icon from '@/components/icon'
import React, { ReactNode } from 'react'
import styles from './index.module.css'

type UserTipsInfoType = {
  /** 组件内容 */
  slotContent?: ReactNode
  /** 图标名称 */
  iconName?: string
  /** 自定义的 class 样式 */
  classStyle?: React.HTMLAttributes<HTMLDivElement> | string
}

function UserTipsInfo(props: UserTipsInfoType) {
  const { slotContent, classStyle, iconName = 'msg' } = props
  return (
    <div className={`user-tips ${styles.scoped} ${classStyle}`}>
      <div className="icon">
        <Icon name={iconName} />
      </div>
      <div className="slot">{slotContent}</div>
    </div>
  )
}

export default UserTipsInfo
